import React, { FC, useCallback, useEffect, useState } from 'react';

import {
  Box,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';

import { Popup } from '../../components';
import Header from '../../components/header/header';
import NoResults from '../../components/no-results';
import StoreTreeList from '../../components/store-tree-list';

import { ObjectKey } from '../../interfaces/common-interface';
import { getRosterList, getRosterStoreList } from '../../services/roster';
import {
  convertNumberDate,
  dateNumberFormat,
  getDropdownListOnStore,
  getInvitationScopeGroupList,
} from '../../utility';
import styles from './roster.module.scss';

interface ListProps {
  id: string;
  title: string;
  url: string;
  extension: string;
}

interface currentStoreType {
  storeId: string;
  store: string;
  district: string;
  operation: string;
}

const Roster: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  // const [selectedStore, setSelectedStore] = useState<string>("");
  const [userCorrectStoreId, setUserCorrectStoreId] = useState<Array<string>>(
    []
  );
  const [selectedStore, setSelectedStore] = useState<currentStoreType | null>(
    null
  );
  const [defaultStore, setDefaultStore] = useState<currentStoreType | null>(
    null
  );
  const [storeList, setStoreList] = useState<Array<ObjectKey>>([]);
  const [scheduleList, setScheduleList] = useState<Array<ListProps>>([]);
  // const [currentScheduleList, setCurrentScheduleList] = useState<
  //   Array<ListProps>
  // >([]);
  const [open, setOpen] = useState(false);
  const [isLeader, setIsLeader] = useState(false);
  const [isEnded, setIsEnded] = useState<boolean>(false);
  const [apiPagination, setApiPagination] = React.useReducer(
    (prev: PaginationType, next: Partial<PaginationType>) => {
      return { ...prev, ...next };
    },
    {
      page: 1,
      pageSize: 15,
    }
  );
  // const filterScheduleList = useCallback(() => {
  //   const currentScheduleList = scheduleList.filter((schedule: ListProps) => {
  //     return userCorrectStoreId.includes(schedule.id);
  //   });
  //   setCurrentScheduleList(currentScheduleList);
  // }, [selectedStore]);

  // useEffect(() => {
  //   filterScheduleList();
  // }, [filterScheduleList]);

  const handleClosePopup = () => {
    setOpen(false);
  };

  const handleChangeStore = (value: string[]) => {
    const selectedTarget = findStore(value[0]);
    setUserCorrectStoreId(value);
    setSelectedStore(selectedTarget);
    handleClosePopup();
  };

  const findStore = (storeId: string) => {
    let currentStore: ObjectKey | null = null;
    let operationName: string = '';
    let districtName: string = '';
    storeList.every((option: ObjectKey) => {
      if (option.value === storeId) {
        currentStore = option;
        return false;
      }
      if (option.children) {
        operationName = option.label;
        option.children.every((childrenOption: any) => {
          if (childrenOption.value === storeId) {
            currentStore = childrenOption;
            return false;
          }
          if (childrenOption.children) {
            districtName = childrenOption.label;
            childrenOption.children.every((lastChildren: any) => {
              if (lastChildren.value === storeId) {
                currentStore = lastChildren;
                return false;
              }
              return true;
            });
          }
          return Boolean(!currentStore);
        });
      }
      return Boolean(!currentStore);
    });

    return convertSelectedStoreObject(
      operationName,
      districtName,
      currentStore
    );
  };

  function convertSelectedStoreObject(
    operationName: string,
    districtName: string,
    storeObj: ObjectKey | null
  ) {
    return {
      storeId: storeObj ? storeObj.value : '',
      store: storeObj ? storeObj.label : '',
      district: districtName,
      operation: operationName,
    };
  }

  useEffect(() => {
    let active = true;

    const getList = async () => {
      try {
        const response = await getRosterStoreList();
        if (
          response.status === 200 &&
          response.data.storeInfo.district &&
          response.data.storeInfo.district.length > 0
        ) {
          const convertStoreList = getInvitationScopeGroupList(
            response.data.storeInfo.district
          );

          if (active) {
            setStoreList(convertStoreList);
            const initStore = convertSelectedStoreObject(
              convertStoreList[0].label,
              convertStoreList[0].children[0].label,
              convertStoreList[0].children[0].children[0]
            );
            if (
              convertStoreList.length > 1 ||
              convertStoreList[0].children.length > 1 ||
              convertStoreList[0].children[0].children.length > 1
            ) {
              setIsLeader(true);
              setDefaultStore(initStore);
              setIsLoading(false);
            } else {
              setUserCorrectStoreId([initStore.storeId]);
              setSelectedStore(initStore);
            }
          }
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        throw error;
      }
    };

    getList();

    return () => {
      active = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let active = true;

    const getScheduleList = async () => {
      try {
        const response = await getRosterList(selectedStore?.storeId ?? '', {
          page: apiPagination.page,
          pageSize: apiPagination.pageSize,
        });
        if (response.status === 200 && response.data.rosterInfo) {
          const rosterList: Array<ListProps> = [];

          response.data.rosterInfo.forEach((roster: ObjectKey) => {
            const rosterDateRange = roster.rosterDate.split('_');
            const rosterName =
              convertNumberDate(
                moment(rosterDateRange[0], 'YYYYMMDD').unix(),
                true
              ) +
              ' - ' +
              convertNumberDate(
                moment(rosterDateRange[1], 'YYYYMMDD').unix(),
                true
              ) +
              ' ' +
              selectedStore?.store;
            rosterList.push({
              id: roster._id,
              title: rosterName,
              url: roster.rosterPath,
              extension: roster.rosterExtension,
            });

            if (
              apiPagination.page >= response.data.totalPage ||
              !response.data.totalPage
            ) {
              setIsEnded(true);
            } else {
              setApiPagination({ page: apiPagination.page + 1 });
            }
          });
          if (active) {
            setScheduleList(rosterList);
          }
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        throw error;
      }
    };

    if (!open && selectedStore) {
      if (isLoading) {
        setIsLoading(true);
      }
      setScheduleList([]);
      getScheduleList();
    }

    return () => {
      active = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStore, open]);

  async function fetchMoreData() {
    try {
      const response = await getRosterList(selectedStore?.storeId ?? '', {
        page: apiPagination.page,
        pageSize: apiPagination.pageSize,
      });
      if (response.status === 200 && response.data.rosterInfo) {
        const rosterList: Array<ListProps> = [];

        response.data.rosterInfo.forEach((roster: ObjectKey) => {
          const rosterDateRange = roster.rosterDate.split('_');
          const rosterName =
            convertNumberDate(
              moment(rosterDateRange[0], 'YYYYMMDD').unix(),
              true
            ) +
            ' - ' +
            convertNumberDate(
              moment(rosterDateRange[1], 'YYYYMMDD').unix(),
              true
            ) +
            ' ' +
            selectedStore?.store;
          rosterList.push({
            id: roster._id,
            title: rosterName,
            url: roster.rosterPath,
            extension: roster.rosterExtension,
          });
        });

        if (apiPagination.page >= response.data.totalPage) {
          setIsEnded(true);
        } else {
          setApiPagination({ page: apiPagination.page + 1 });
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  }

  const PopupHeader = () => {
    return (
      <Grid container alignItems="center" className={styles.storePopupHeader}>
        <Grid item xs="auto">
          <IconButton
            id="closeStorePopupButton"
            className={styles.storePopupCloseButton}
            disableRipple
            onClick={handleClosePopup}
          >
            <img src="/assets/images/arrow_left.svg" alt="" />
          </IconButton>
        </Grid>
        <Grid item xs>
          <Typography variant="h3">{t('roster.selectStore')}</Typography>
        </Grid>
      </Grid>
    );
  };

  const downloadRoster = async (
    fileName: string,
    url: string,
    extension: string
  ) => {
    try {
      const fileResponse = await fetch(url, {
        method: 'get',
        credentials: 'include',
      });
      const blob = await fileResponse.blob();
      const urlObject = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = urlObject;
      link.download = `${fileName}${extension}`;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      URL.revokeObjectURL(urlObject); // Clean up the temporary URL
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Header
        enableBackButton
        disableBottomBorder
        title={t('roster.title')}
        closeButtonFunction={() => {
          // console.log("closeButtonFunction");
          const prevPage = sessionStorage.getItem('prevPage') ?? null;
          if (prevPage) {
            window.location.href = prevPage;
          } else {
            navigate('/home');
          }
        }}
      />
      {(selectedStore || defaultStore) && (
        <Grid container className={styles.rosterCurrentStore}>
          <Grid
            container
            item
            xs
            alignItems="center"
            className={styles.rosterCurrentStoreInfo}
          >
            <Grid item xs="auto">
              <img src="/assets/images/store.svg" alt="" />
            </Grid>
            {selectedStore ? (
              <Grid item xs>
                <Typography variant="body1">{selectedStore.store}</Typography>
                <Typography variant="body2">{`${selectedStore.operation} | ${selectedStore.district}`}</Typography>
              </Grid>
            ) : (
              <Grid item xs>
                <Typography variant="body1"></Typography>
                <Typography variant="body2">
                  {defaultStore?.operation}
                </Typography>
              </Grid>
            )}
          </Grid>
          {isLeader && (
            <Grid item xs="auto">
              <IconButton
                id="openStoreListPopup"
                className={styles.rosterCurrentStoreButton}
                disableRipple
                onClick={() => setOpen(true)}
              >
                <img src="/assets/images/chevron_down.svg" alt="" />
              </IconButton>
            </Grid>
          )}
        </Grid>
      )}
      <Typography variant="h5" className={styles.rosterTitle}>
        {t('roster.title')}
      </Typography>
      <Grid
        container
        direction="column"
        item
        xs
        className={styles.rosterContainer}
        id="scrollableDiv"
      >
        {!isLoading ? (
          <>
            {scheduleList.length > 0 ? (
              <InfiniteScroll
                dataLength={scheduleList.length}
                next={fetchMoreData}
                hasMore={!isEnded}
                loader={
                  <>
                    <Box
                      className={styles.rosterListItem}
                      style={{ padding: '0 16px' }}
                    >
                      <Skeleton variant="rounded" height={40} />
                    </Box>
                  </>
                }
                scrollableTarget="scrollableDiv"
              >
                <List className={styles.rosterList}>
                  {isLoading &&
                    Array.from(new Array(3)).map((_, index) => (
                      <Box key={index} className={styles.rosterListItem}>
                        <Skeleton variant="rounded" height={20} />
                      </Box>
                    ))}

                  {scheduleList.map((item: ListProps, index: number) => (
                    <ListItemButton
                      key={index}
                      // component="a"
                      // href={item.url}
                      // target="_black"
                      className={styles.rosterListItem}
                      onClick={() =>
                        downloadRoster(item.title, item.url, item.extension)
                      }
                    >
                      <ListItemText primary={item.title} />
                      <ListItemIcon>
                        <img
                          src="/assets/images/chevron_right_black_v2.svg"
                          alt=""
                        />
                      </ListItemIcon>
                    </ListItemButton>
                  ))}
                </List>
              </InfiniteScroll>
            ) : (
              <>
                {!defaultStore ? (
                  <NoResults title="" desc={t('roster.withoutRosterMsg')} />
                ) : (
                  <NoResults title="" desc={t('roster.pleaseSelectStore')} />
                )}
              </>
            )}
          </>
        ) : (
          <Box className={styles.rosterListItem} style={{ padding: '16px' }}>
            {Array.from(new Array(10)).map((_, index) => (
              <Skeleton
                variant="rectangular"
                height={20}
                style={{ marginBottom: '20px' }}
              />
            ))}
          </Box>
        )}
      </Grid>
      <Popup
        id="selectPopup"
        isOpen={open}
        setIsOpen={() => handleClosePopup()}
        fullScreen
        disableActions
        title={<PopupHeader />}
        content={
          <StoreTreeList
            value={userCorrectStoreId}
            options={storeList}
            onChange={handleChangeStore}
          />
        }
      />
    </>
  );
};

export default Roster;
