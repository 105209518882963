import { FC, useCallback, useEffect, useState } from "react";
import styles from "./store-tree-list.module.scss";
import { ObjectKey } from "../../interfaces/common-interface";
import InvitedGroupIcon from "../invited-group-icon";
import CheckboxTree from "react-checkbox-tree";
import { Box } from "@mui/material";

interface StoreTreeListProps {
  value?: string | Array<string>;
  options: Array<ObjectKey>;
  multiple?: boolean;
  onChange: (value: string[]) => void;
  valueKey?: string;
}

const CheckboxIcon = (props: { isSelect?: boolean }) => {
  return (
    <Box className={styles.checkboxIcon}>
      {props.isSelect ? (
        <img src="/assets/images/checkbox_tick.svg" alt="" />
      ) : (
        <span></span>
      )}
    </Box>
  );
};

const ExpendArrow = (props: { isOpen?: boolean }) => {
  return (
    <Box data-open={Boolean(props.isOpen)} className={styles.expendArrow}>
      <img
        src="/assets/images/chevron_right.svg"
        alt={Boolean(props.isOpen) ? "Close" : "Open"}
      />
    </Box>
  );
};

const StoreTreeList: FC<StoreTreeListProps> = (props) => {
  const { value, options, multiple, valueKey = "value", onChange } = props;
  let selectedValue =
    value === undefined ? [] : Array.isArray(value) ? value : [value];

  const [optionsList, setOptionsList] = useState<Array<ObjectKey>>([]);
  const [expanded, setExpanded] = useState<Array<string>>([]);

  const findOptionsByValue = (option: ObjectKey, value: string) => {
    let selectedExpanded: string[] = [];
    const selected = selectedValue.find(
      (selected: string) => selected === value
    );
    if (selected) {
      if (option.districtValue) {
        selectedExpanded = [...selectedExpanded, option.districtValue];
      }
      if (option.operationValue) {
        selectedExpanded = [...selectedExpanded, option.operationValue];
      }
    }

    return selectedExpanded;
  };

  const addIconToGroupOptionsList = useCallback(() => {
    let convertExpandedList: string[] = [];
    const convertList = options.map((option: ObjectKey) => {
      let children: any = option.children;
      const selectedExpanded = findOptionsByValue(option, option[valueKey]);
      if (selectedExpanded.length > 0) {
        convertExpandedList = [...convertExpandedList, ...selectedExpanded];
      }
      if (option.children) {
        children = option.children.map((childrenOption: any) => {
          let children: any = childrenOption.children;
          const selectedExpanded = findOptionsByValue(
            childrenOption,
            childrenOption[valueKey]
          );
          if (selectedExpanded.length > 0) {
            convertExpandedList = [...convertExpandedList, ...selectedExpanded];
          }
          if (childrenOption.children) {
            children = childrenOption.children.map((lastChildren: any) => {
              const selectedExpanded = findOptionsByValue(
                lastChildren,
                lastChildren[valueKey]
              );
              if (selectedExpanded.length > 0) {
                convertExpandedList = [
                  ...convertExpandedList,
                  ...selectedExpanded,
                ];
              }
              return (lastChildren = {
                ...lastChildren,
                className: styles.optionLastItem,
                icon: <img src="/assets/images/structure_icon.svg" alt="" />,
              });
            });
          }
          return (childrenOption = {
            ...childrenOption,
            children: children,
            className: styles.optionSubItem,
            icon: <img src="/assets/images/structure_icon.svg" alt="" />,
          });
        });
      }
      return (option = {
        ...option,
        children: children,
        className: styles.optionItem,
        icon: <InvitedGroupIcon />,
      }) as any;
    });

    setOptionsList(convertList);
    if (convertExpandedList.length > 0) {
      setExpanded(convertExpandedList);
    }
  }, [options]);

  useEffect(() => {
    addIconToGroupOptionsList();
  }, [addIconToGroupOptionsList]);

  if (optionsList.length <= 0) return null;

  return (
    <Box className={styles.checkboxTreeList}>
      <CheckboxTree
        nodes={optionsList as any[]}
        checked={selectedValue as Array<string>}
        expanded={expanded}
        onCheck={(checked, targetNode) => {
          if (targetNode.children) {
            let openExpanded = [targetNode.value];
            targetNode.children.map((item) => {
              if (item.children) {
                openExpanded = [...openExpanded, item.value];
              }
            });
            if (multiple) {
              setExpanded([...expanded, ...openExpanded]);
            } else {
              setExpanded(openExpanded);
            }
          }
          if (multiple) {
            onChange(checked);
          } else {
            const selected = checked.find(
              (checkedItem: string) => checkedItem !== selectedValue[0]
            );
            onChange([selected as string]);
          }
        }}
        onExpand={(expanded) => {
          setExpanded(expanded);
        }}
        checkModel={multiple ? "all" : "leaf"}
        icons={{
          check: <CheckboxIcon isSelect={true} />,
          uncheck: <CheckboxIcon />,
          halfCheck: <CheckboxIcon />,
          expandClose: <ExpendArrow />,
          expandOpen: <ExpendArrow isOpen={true} />,
        }}
        // showExpandAll
        onlyLeafCheckboxes={!multiple}
      />
    </Box>
  );
};

export default StoreTreeList;
