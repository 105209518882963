import { AxiosPromise } from "axios";
import { instance } from "./api-services";

const servicePath = "/event-roster-and-schedule/";

export const getRosterList = (
  storeId: string,
  params?: {
    page?: number;
    pageSize?: number;
  }
): AxiosPromise =>
  instance({
    method: "GET",
    id: `getRosterList-${storeId}-${params?.page}-${params?.pageSize}}`,
    url: `${servicePath}/roster/${process.env.REACT_APP_API_LOCATION}/${storeId}`.replace(
      "//",
      "/"
    ),
    params: params,
  });

export const getRosterStoreList = (): AxiosPromise =>
  instance({
    method: "GET",
    id: `getCard-receiver`,
    url: `${servicePath}/roster/${process.env.REACT_APP_API_LOCATION}/store`.replace(
      "//",
      "/"
    ),
  });
