import React, { FC, useEffect, useMemo, useState } from "react";
import {
  Box,
  Grid,
  List,
  ListItem,
  Typography,
  ListItemButton,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import theme from "../../theme";
import { useTranslation } from "react-i18next";
import styles from "./language-dropdown.module.scss";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Checkbox } from "..";
import { ToggleButton, Popup, ActionItem, DragAndDrop } from "..";
import { TransitionProps } from "@mui/material/transitions";
import InputField from "../input-field";
import { getSurveyQuestionType } from "../../services/survey";
import { FormValueType } from "../../pages/survey/useSurveyFormHook";

interface LanguageDropdownProps {
  value: Array<string>;
  disabled?: boolean;
  onChange: (selected: Array<string>) => void;
}

const LanguageDropdown: FC<LanguageDropdownProps> = (props) => {
  const { t } = useTranslation();
  const [selectedLang, setSelectLang] = useState<Array<string>>(props.value);
  const [openLanguageDropdown, setOpenLanguageDropdown] = useState(false);
  const languageList = [
    {
      name: t('general.languages.en'),
      value: "en",
    },
    {
      name: t('general.languages.tc'),
      value: "tc",
    },
  ];

  useEffect(() => {
    setSelectLang(props.value);
  }, [props.value]);
  const handleToggle = (value: string) => {
    if (!selectedLang.some((lang: string) => lang === value)) {
      setSelectLang((prev) => [...prev, value]);
    } else {
      if (selectedLang.length > 1) {
        let newLangList: typeof selectedLang = [];
        selectedLang.forEach((lang: string) => {
          if (lang !== value) {
            newLangList.push(lang);
          }
        });
        setSelectLang(newLangList);
      }
    }
  };

  const handleReset = () => {
    setSelectLang(props.value);
  };

  const handleConfirm = () => {
    props.onChange(selectedLang);
    setOpenLanguageDropdown(false);
  };

  const handleOpenDropdown = () => {
    if (props.disabled) return false;
    setOpenLanguageDropdown(true);
  };

  const LangOption = (props: { name: string; value: string }) => {
    const isSelected = selectedLang.some(
      (lang: string) => lang.toLowerCase() === props.value.toLowerCase()
    );

    return (
      <ListItem disablePadding>
        <ListItemButton
          className={styles.languageSelectOption}
          onClick={() => handleToggle(props.value)}
        >
          <ListItemText primary={props.name} />
          <ListItemIcon>
            <Checkbox checked={isSelected} setChecked={(checked) => false} />
          </ListItemIcon>
        </ListItemButton>
      </ListItem>
    );
  };

  return (
    <>
      <Box
        className={`${styles.languageSelectField} ${
          props.disabled && styles.disabled
        }`}
        onClick={handleOpenDropdown}
      >
        <Grid item xs>
          <Typography variant="body1">
            {t("event.displayLanguageLabel", {
              lang: props.value.join(", ").toUpperCase(),
            })}
          </Typography>
        </Grid>
        <Grid item xs="auto">
          <img src="/assets/images/chevron_down.svg" alt="" />
        </Grid>
      </Box>
      <Popup
        isOpen={openLanguageDropdown}
        title={
          <>
            <Typography variant="h4" className={styles.languageSelectHeader}>
              {t("event.displayLanguage")}
            </Typography>
            <Typography
              variant="body2"
              className={styles.languageSelectDescription}
            >
              {t("event.displayLanguageDescription")}
            </Typography>
          </>
        }
        content={
          <List disablePadding className={styles.languageSelectList}>
            {/* eslint-disable-next-line */}
            {languageList.map((lang: any, index: number) => (
              <LangOption key={index} name={lang.name} value={lang.value} />
            ))}
          </List>
        }
        setIsOpen={(toOpen: boolean) => setOpenLanguageDropdown(toOpen)}
        confirmBtnText={t("general.save")}
        onClickCancel={handleReset}
        onClickConfirm={handleConfirm}
      />
    </>
  );
};

export default LanguageDropdown;
